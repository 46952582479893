@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
@layer base {
    body {
        font-family: 'Raleway', sans-serif;
    }
    li {
        @apply px-4;
        @apply cursor-pointer;
    }
}

.home-pj {
    background: #1240C6 50% no-repeat;
    background-image: linear-gradient(to bottom, rgb(10, 12, 70), rgba(10, 20, 70, 0.5) 90%), url(https://jurski-consulting-website-data.s3.amazonaws.com/website/img/code2.jpg);
    /* background-position-x: center; */
    background-size: cover;
    height: 100vh;
    filter: brightness(1.4) contrast(1.1);
}

.nav-pj {
    background: rgb(10, 12, 70) 50% no-repeat;
    /* background-image: linear-gradient(to top, rgb(10, 12, 70), rgba(10, 20, 70, 0.5) 90%); */
    /* background-position-x: center; */
    background-size: cover;
    filter: brightness(1.4) contrast(1.1);
}

.about-pj {
    background: #0a0a5a 100% no-repeat;
    background-image: linear-gradient(to top, #0a0a5a, rgba(8, 54, 238, 0.05)), url(../src/assets/piotr.jpg);
    background-size: 50% auto;
    /* filter: brightness(0.75) contrast(1.3); */
}

.skills-pj {
    background: #0a0a5a 100% no-repeat;
    /* background-image: linear-gradient(to bottom, rgba(10, 10, 106) 95%, #1240C6); */
    background-size: 100% auto;
    /* filter: brightness(0.75) contrast(1.3); */
}

.contact-pj {
    background: #0a0a5a 100% no-repeat;
    /* background-image: linear-gradient(to top, rgba(10, 10, 106) 95%, #1240C6); */
    background-size: 100% auto;
    /* filter: brightness(0.75) contrast(1.3); */
}

.content-div {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 250px;
}

.pj-jobtitle {
    width: fit-content;
    max-width: fit-content;
}

.dot {
    color: #fff;
}

.content-div:hover {
    background-image: linear-gradient( to right, #0a0a5a, hsla(242, 74%, 61%, 0.8))!important;
}

@media screen and (max-width: 767px) {
    .about-pj {
        background-image: unset;
    }
}